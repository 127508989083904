<template>
  <div class="p-3">
    <div class="row d-flex align-items-center">
      
      <div class="col-xl-auto col-lg-auto col-md-auto col-4 border text-center">
        <button
          @click="refersh()"
          class="btn btn-secondary d-flex justify-content-center align-items-center"
        >
          <i class="bi bi-x-octagon fs-4"></i>
          <span class="m-2"> Actualiser </span>
        </button>
      </div>

      <div class="col-xl-auto col-lg-auto col-md-auto col-4 border text-center">
        <button
          @click="$router.push({ name: 'layout' })"
          class="btn btn-warning d-flex justify-content-center align-items-center"
        >
          <i class="bi bi-box fs-4"></i>
          <span class="m-2"> Magasinier </span>
        </button>
      </div>

      <div class="col-xl-auto col-lg-auto col-md-auto col-4 border text-center">
        <button
          @click="logout()"
          class="btn btn-danger d-flex justify-content-center align-items-center"
        >
          <i class="bi bi-x-octagon fs-4"></i>
          <span class="m-2"> Éteindre </span>
        </button>
      </div>
    </div>

    <div
      class="row text-start my-2 py-3 shadow mx-1 rounded align-items-center"
      v-for="(stock, index) in stocks"
      :key="index++"
    >
      <div class="col-1">
        {{ index }}
      </div>

      <div class="col-6">
        <span v-if="stock" class="text-uppercase fw-bolder">
          {{ stock.itemFullName }}
        </span>
      </div>

      <div class="col-2">
        <div v-if="stock" class="text-uppercase fw-bolder">
          {{ stock.fullName }}
        </div>
      </div>
      <div class="col-3 text-end">
        <span
          class="p-2 rounded-2 text-uppercase fw-bolder text-end"
          :class="
            stock.quantity == 0
              ? 'bg-danger text-white'
              : stock.quantity <= 5
              ? 'bg-warning'
              : 'text-success'
          "
        >
          {{ parseFloat(stock.quantity).toFixed(3) }}
        </span>
      </div>
    </div>
  </div>
</template>
<script>
import { mapGetters } from "vuex";
export default {
  computed: {
    ...mapGetters("stock", {
      stocks: "getStocks",
      stockFullNames: "getStockFullNames",
    }),
  },
  mounted() {
    this.$store.dispatch("stock/getAll");
    this.$store.dispatch("stock/fetchStockFullNames");
  },
};
</script>
